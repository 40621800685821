.service-section {
  background-color: white;
  color: black;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 30%;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
}

@media screen and (min-width: 1000px) {
  .row {
    padding: 20px;
  }
}
