.footer__container{
  display: flex;
  gap: 3rem;
  background-color: #000;
}
.footer-container2{
  position: relative;
  margin-bottom: -10px;
}
.footer__col1{
  flex: 2;
  padding: 2%;
  
}

.footer__col2,
.footer__col3,
.footer__col4{
  flex: 1;
  padding-bottom: 1rem;
}

.footer__col1__title{
  font-size: 3rem;
  margin-bottom: 1rem;
}

.footer__column{
  font-size: 1rem;
  margin-bottom: 2rem;
  padding: 1%;
  padding-top: 15%
}

.foooter__li{
  margin-bottom: 1rem;
}

.footer__heading{
  font-size: 2rem;
}



p{
  color: #fff;
  text-align: justify;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

@media only screen and (max-width: 768px){
  .footer__container{
    flex-direction: column;
    gap: 0rem;
    margin: 0rem;

  }

  .footer__col1,
  .footer__col2,
  .footer__col3,
  .footer__col4{
    padding: 0rem;
    margin: 0rem;
    padding-left: 5%;
  }

  .footer__col1{
    padding: 5%;
  }

  .footer__column{
    padding-top: 2%;
  }

}