/* Style inputs with type="text", select elements and textareas */
input[type="text"],
textarea {
  width: 100%; /* Full width */
  padding: 7px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  font-size: large;
  background-color: rgba(255, 255, 255, 0.04);
  box-shadow: 10px 20px 50px rgb(5, 2, 26);
  color: #fff;
  outline: none;
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  /* background-color: #b7b8e4; */
  background-color: #959cc2;
  color: #05051f;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: large;
  width: 100%; /* Full width */
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  /* background-color: white; */
  background-color: #05051f;
  color: #959cc2;
  border: 1px solid #959cc2;
}

/* Add a background color and some padding around the form */
.mail-container {
  /* border-radius: 5px; */
  background-color: #05051f;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 250px;
  /* height: 100%; */ /*commented this to solve responsive issue on mobile view */
  width: 100%;
}

.mail-container > h1 {
  color: #fff;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.mail-container > p {
  color: white;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
  text-align: center;
}

label {
  color: #b7b8e4;
  font-size: large;
}

@media screen and (max-width: 820px) {
  .mail-container {
    padding-top: 1px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 0px;
  }
}

.mail-container > hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 7%;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .mail-container > hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 15%;
  }
}
