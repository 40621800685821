@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "PT Sans", sans-serif;
	/* background-color : #05051f; */
	/* padding: 100px 0px; */
}

.home,
.services,
.products,
.sign-up,
.aboutus {
	display: -webkit-flex;
	display: flex;
	height: 90vh;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-size: 3rem;
	padding: 100px;
}

.services {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	color: #05051f;
	font-size: 100px;
	background-color: #05051f;
}

.products {
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.sign-up {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.aboutus {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.container > h1 {
	font-size: 7vmin;
	line-height: 1.1;
	color: #fefefe;
	background-color: #05051f;
}
.about-section > p {
	font-size: 3vmin;
	line-height: 1.1;
}

html {
	block-size: 100%;
	inline-size: 100%;
	max-width: 100%;
}

body {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
	overflow-x: hidden;
	border-left: none;
	border-right: none;
  }
  
  body::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
  }
  
.particles {
	/* background-color: rgb(5, 20, 66); */
	height: 100vh;
	width: 100%;
}

.hero-container {
	background-color: #05051f;
	height: 100vh;
	width: 100%;
}

.flies-text-title {
	margin-top: -455px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
}

@media screen and (max-width: 960px) {
	.flies-text-title {
		-webkit-transform: scale(0.75);
		        transform: scale(0.75);
	}
}

@media screen and (max-width: 450px) {
	.flies-text-title {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
	}
}

@media screen and (max-width: 350px) {
	.flies-text-title {
		-webkit-transform: scale(0.4);
		        transform: scale(0.4);
	}
}

@media screen and (max-width: 265px) {
	.flies-text-title {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
	}
}

.flies-text-slogan {
	margin-top: -50px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	/*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
	/* object-fit: contain; */
}

/*
.typing-text-slogan {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
*/

.typing-text-slogan {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	object-fit: contain;
	position: absolute;
	top: 62%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@media screen and (max-width: 960px) {
	.typing-text-slogan {
		width: 100%;
	}
}

/* .hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
} */

.hero-container > h1 {
	color: #fff;
	font-size: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.hero-container > p {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	object-fit: contain;
}

.cursor {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
}

.title {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	top: 48%;
	left: 50%;
}

.title > h1 {
	color: #fff;
	font-family: "Times New Roman", serif;
	text-shadow: 5px 1px #19199e;
	font-weight: 500;
	font-size: 8vmax;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

/*
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background-color: rgb(8, 8, 102);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}






.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.socialmedia a.fa {
  border: 100px solid white;
  padding: 10px;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  margin-right: 2px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
*/

.loader {
	position: absolute;
	top: 71%;
	left: 50%;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	box-shadow: 0px 0px 5px 5px rgba(90, 29, 5, 0.8);
	text-align: center;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #fff;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "avenir next", avenir, -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  color: #374047;
}

#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 100%;
  background-color: #1e282f;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 60%;
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.column-small {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 13%;
}
@media screen and (max-width: 500px) {
  .column-small {
    width: 100%;
  }
}

.card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 40px;
  padding: 40px;
  /* background-color: #ffffff; */
  /* background-color: #1d223b; */
  background-color: rgba(255, 255, 255, 0.05);

  /* box-shadow: 0 20px 50px rgba(2, 9, 73, 0.8); */
  box-shadow: 10px 20px 50px rgb(8, 5, 24);
  transition: box-shadow 0.5s;
  will-change: transform;
  border-radius: 2%;
}

.card-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  /* color: #12126e; */
  color: #fff;
}

.card-body {
  margin-top: 27px;
  margin-bottom: 0px;
  line-height: 1.5;
  font-size: 20px;
  text-align: justify;
  /* color: #0b0b42; */
  color: #fff;

  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.image-container {
  margin-top: auto;
  padding-top: 27px;
  padding-bottom: 0px;
  @media screen and (min-width: 576px) {
    padding-bottom: 30px;
  }
}

.image-inner-container {
  width: 100%;
  margin: 0 auto;
  max-width: 250px;
}

.ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ratio-inner > img {
  width: 100%;
}

.services-section {
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  background-color: #05051f;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .services-section {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.cards {
  padding: 4rem;
  background: #5b5bf0;
}

.card-pop {
  /* background: hsl(293, 81%, 86%); */
  /* background: hsl(190, 81%, 89%); */
  /* background: hsl(194, 82%, 89%); */
  background: hsl(293, 81%, 90%);
}

h1 {
  text-align: center;
  color: #2b2bf7;
}

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 7%;
}

@media screen and (max-width: 500px) {
  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 15%;
  }
}

.cards__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding: 5rem 10rem 10rem 10rem;
  background-color: #fafafa;
}

.cards__container > h1 {
  font-size: 7vmin;
  line-height: 1.1;
}
.cards_container > p {
  font-size: 3vmin;
  line-height: 1.1;
}

html {
  block-size: 100%;
  inline-size: 100%;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: rgb(255,255,255,0.05);
}

/*card hovering effect below*/

.cards__item :hover {
  background-color: rgb(255,255,255,0.05);
  -webkit-align-items: center;
          align-items: center;
}

.cards__item :hover .cards__item__text {
  color: #f9f9fa;
  font-size: 22px;
}

.cards__item :hover .cards__item__img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/*card hovering effect above*/

.cards__item__link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  width: 100%;
  /* box-shadow: 0 6px 20px rgb(255,255,255,0.05); */
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: rgb(255,255,255,0.05);
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: -webkit-flex;
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

/*
.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
*/

.footer-container {
  /* background: linear-gradient(00deg,rgb(17,0,154) 0%, rgb(60, 61, 155) 90%); */
  background: #000;
  padding: 0rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  /*to keep footer at the bottom-Akilax0 */
  
  position:relative;
  bottom:0;   
  width: 100%;

}

.footer-subscription {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer-link-wrapper {
  display: -webkit-flex;
  display: flex;
}

.footer-link-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .social-media-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

*{
	margin:0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: 'Poppins', sans-serif; */
  
}

h1
{
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 0rem;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin-bottom: 0.7rem;
}
p
{
    font-size: 0.9rem;
    color: #000000;
}
.social_links
{
    padding: 1rem;
    /* background: linear-gradient(180deg,rgb(17,0,154) 0%, rgb(60, 61, 155) 90%); */
    background: #000;
    border-radius: 1rem;
    margin-top: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}
.social_links .combo
{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.4s ease-in;
    margin: 0.5rem 0.5rem;
    height: 3em;
    width: 9em;
    line-height: 3em;
}
.combo .circle
{
    color: #f9f9f9;
    transition: 0.4s ease-in;
    font-size: 3em;
}
.combo .icon
{
    color: #05051f;
    font-size: 2rem;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    transition: 0.2s ease-in;
}
.combo:hover
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.combo:hover , .combo:hover .icon
{
    color:white;
}
.ig:hover , .ig:hover .circle
{
    color:#e91e63;
}
.fb:hover , .fb:hover .circle
{
    color:#2196f3;
}
.yt:hover , .yt:hover .circle
{
    color:#f44336;
}
.tw:hover , .tw:hover .circle
{
    color:#2196f3;
}
.gt:hover , .gt:hover .circle{
    color: #1f1f1f;
}
.container{
    background: #05051f;
}


.row.spec {
  margin-left: 6%;
  margin-right: 4%;
}

.tech-card {
  margin: 1vh;
  width: 13vw;
  height: 12vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(255, 255, 255, 0.905);
  box-shadow: 10px 20px 50px rgba(23, 15, 68, 0.878);
  transition: box-shadow 0.5s;
  will-change: transform;
  border-radius: 3%;
}

.techImage {
    width: 80%;
    height: 80%;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.expImage {
    height: 50%;
}


  /* Responsive layout - makes a two columns-layout instead of four columnss */
  /* @media screen and (max-width: 800px) {
    .tech-container h1 {
        align-content: center;
        border-radius: 5px;
    }
    .columns {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 50%;
    }
  }
   */
  /* Responsive layout - makes the two columnss stack on top of each other instead of next to each other */
  /* @media screen and (max-width: 600px) {
    .tech-container h1 {
        align-content: center;
        border-radius: 5px;
    }
    .columns {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  } */

  .techImagesContainer {
    display: none;
  }


  @media only screen and (max-width: 1500px) {
    .row .spec{
      margin-left: 15%;
    }
  }

  @media only screen and (max-width: 600px) {
    .container-fluid{
        margin-left: 5%;
        display: none;
    }

    .tech-card {
        margin: 1%;
        margin-top: 1%;
        padding: 3%;
        width: 10%;
        height: 10%;
        margin-left: 8%;
    }

    .techImage {
        width:80%;
        height: 80%;
        margin-top: 10%;
    }

    .expImage {
        height: 50%;
    }

    .techImagesContainer {
      display :block;
      margin: 10%;
    }
}
.tech {
  padding: 0 0 0;
  /* padding: 6rem 0 0; */
  /* background: #5b5bf0; */
}

.tech-container {
  background: #ffffff;
  display: table;
  width: 1000px;
  height: 60px;
  padding: 2%;
  margin: auto;
  /* box-sizing: border-box; */
  border-radius: 25px;
}

h1 {
  text-align: center;
  color: #fdfdfd;
  font-size: 3vmax;
}

.tech__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding: 10rem 10rem 10rem 10rem;
  background-color: #fafafa;
}

.tech__container > h1 {
  font-size: 7vmin;
  line-height: 1.1;
}
.tech_container > p {
  font-size: 3vmin;
  line-height: 1.1;
}

html {
  block-size: 100%;
  inline-size: 100%;
}

.tech__wrapper {
  position: relative;
  margin: 50px 50px;
}

.tech__items {
  margin-bottom: 24px;
}

.tech__item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 1rem;
  /* border-radius: 10px; */
  background-color: #fdfdfd;
}

/*card hovering effect below*/

.tech__item :hover {
  background-color: #fdfdfd;
  -webkit-align-items: center;
          align-items: center;
}

.tech__item :hover .tech__item__text {
  color: #f9f9fa;
  font-size: 22px;
}

.tech__item :hover .tech__item__img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/*card hovering effect above*/

.tech__item__link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  width: 25%;
  box-shadow: 0 6px 20px #f9f9f92b;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  /* border-radius: 10px; */
  overflow: hidden;
  text-decoration: none;
}

.tech__item__pic-wrap {
  position: relative;
  width: 50%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.tech__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.tech__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50%;
  max-width: 50%;
  height: 50%;
  max-height: 50%;
  object-fit: none;
  transition: all 0.2s linear;
}

.tech__item__img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.tech__item__info {
  padding: 20px 30px 30px;
}

.tech__item__text {
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 10%;
    object-fit: fill;
  }
}

@media only screen and (min-width: 1024px) {
  .tech__items {
    display: -webkit-flex;
    display: flex;
    
  }
}

@media only screen and (max-width: 1024px) {
  .tech__item {
    margin-bottom: 2rem;
    position: fixed;
  }
}

/*
.tech {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.tech__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.tech__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.tech__items {
  margin-bottom: 24px;
}

.tech__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.tech__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.tech__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.tech__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.tech__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.tech__item__img:hover {
  transform: scale(1.1);
}

.tech__item__info {
  padding: 20px 30px 30px;
}

.tech__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .tech__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .tech__item {
    margin-bottom: 2rem;
  }
}
*/

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
    white-space: nowrap;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
.navbar {
  background: #05051f;
  /* background: transparent; */
  -webkit-backface-visibility: true;
          backface-visibility: true;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  /* max-width: 1400px; */
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  white-space: nowrap;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 75%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #202075;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: fixed;
    top: 0%;
    left: -30%;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

/* Style inputs with type="text", select elements and textareas */
input[type="text"],
textarea {
  width: 100%; /* Full width */
  padding: 7px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  font-size: large;
  background-color: rgba(255, 255, 255, 0.04);
  box-shadow: 10px 20px 50px rgb(5, 2, 26);
  color: #fff;
  outline: none;
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  /* background-color: #b7b8e4; */
  background-color: #959cc2;
  color: #05051f;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: large;
  width: 100%; /* Full width */
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  /* background-color: white; */
  background-color: #05051f;
  color: #959cc2;
  border: 1px solid #959cc2;
}

/* Add a background color and some padding around the form */
.mail-container {
  /* border-radius: 5px; */
  background-color: #05051f;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 250px;
  /* height: 100%; */ /*commented this to solve responsive issue on mobile view */
  width: 100%;
}

.mail-container > h1 {
  color: #fff;
  font-size: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.mail-container > p {
  color: white;
  font-size: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
  text-align: center;
}

label {
  color: #b7b8e4;
  font-size: large;
}

@media screen and (max-width: 820px) {
  .mail-container {
    padding-top: 1px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 0px;
  }
}

.mail-container > hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 7%;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .mail-container > hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 15%;
  }
}

.footer__container{
  display: -webkit-flex;
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;
  background-color: #000;
}
.footer-container2{
  position: relative;
  margin-bottom: -10px;
}
.footer__col1{
  -webkit-flex: 2 1;
          flex: 2 1;
  padding: 2%;
  
}

.footer__col2,
.footer__col3,
.footer__col4{
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-bottom: 1rem;
}

.footer__col1__title{
  font-size: 3rem;
  margin-bottom: 1rem;
}

.footer__column{
  font-size: 1rem;
  margin-bottom: 2rem;
  padding: 1%;
  padding-top: 15%
}

.foooter__li{
  margin-bottom: 1rem;
}

.footer__heading{
  font-size: 2rem;
}



p{
  color: #fff;
  text-align: justify;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

@media only screen and (max-width: 768px){
  .footer__container{
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 0rem;
    gap: 0rem;
    margin: 0rem;

  }

  .footer__col1,
  .footer__col2,
  .footer__col3,
  .footer__col4{
    padding: 0rem;
    margin: 0rem;
    padding-left: 5%;
  }

  .footer__col1{
    padding: 5%;
  }

  .footer__column{
    padding-top: 2%;
  }

}
.service-section {
  background-color: white;
  color: black;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.column {
  -webkit-flex: 30% 1;
          flex: 30% 1;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (min-width: 1000px) {
  .row {
    padding: 20px;
  }
}

