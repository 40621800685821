.tech {
  padding: 0 0 0;
  /* padding: 6rem 0 0; */
  /* background: #5b5bf0; */
}

.tech-container {
  background: #ffffff;
  display: table;
  width: 1000px;
  height: 60px;
  padding: 2%;
  margin: auto;
  /* box-sizing: border-box; */
  border-radius: 25px;
}

h1 {
  text-align: center;
  color: #fdfdfd;
  font-size: 3vmax;
}

.tech__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding: 10rem 10rem 10rem 10rem;
  background-color: #fafafa;
}

.tech__container > h1 {
  font-size: 7vmin;
  line-height: 1.1;
}
.tech_container > p {
  font-size: 3vmin;
  line-height: 1.1;
}

html {
  block-size: 100%;
  inline-size: 100%;
}

.tech__wrapper {
  position: relative;
  margin: 50px 50px;
}

.tech__items {
  margin-bottom: 24px;
}

.tech__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  /* border-radius: 10px; */
  background-color: #fdfdfd;
}

/*card hovering effect below*/

.tech__item :hover {
  background-color: #fdfdfd;
  align-items: center;
}

.tech__item :hover .tech__item__text {
  color: #f9f9fa;
  font-size: 22px;
}

.tech__item :hover .tech__item__img {
  transform: scale(1.2);
}

/*card hovering effect above*/

.tech__item__link {
  display: flex;
  flex-flow: column;
  width: 25%;
  box-shadow: 0 6px 20px #f9f9f92b;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  /* border-radius: 10px; */
  overflow: hidden;
  text-decoration: none;
}

.tech__item__pic-wrap {
  position: relative;
  width: 50%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.tech__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.tech__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50%;
  max-width: 50%;
  height: 50%;
  max-height: 50%;
  object-fit: none;
  transition: all 0.2s linear;
}

.tech__item__img:hover {
  transform: scale(1.5);
}

.tech__item__info {
  padding: 20px 30px 30px;
}

.tech__item__text {
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 10%;
    object-fit: fill;
  }
}

@media only screen and (min-width: 1024px) {
  .tech__items {
    display: flex;
    
  }
}

@media only screen and (max-width: 1024px) {
  .tech__item {
    margin-bottom: 2rem;
    position: fixed;
  }
}

/*
.tech {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.tech__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.tech__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.tech__items {
  margin-bottom: 24px;
}

.tech__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.tech__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.tech__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.tech__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.tech__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.tech__item__img:hover {
  transform: scale(1.1);
}

.tech__item__info {
  padding: 20px 30px 30px;
}

.tech__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .tech__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .tech__item {
    margin-bottom: 2rem;
  }
}
*/
