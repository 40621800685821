.footer-container {
  /* background: linear-gradient(00deg,rgb(17,0,154) 0%, rgb(60, 61, 155) 90%); */
  background: #000;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /*to keep footer at the bottom-Akilax0 */
  
  position:relative;
  bottom:0;   
  width: 100%;

}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

*{
	margin:0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: 'Poppins', sans-serif; */
  
}

h1
{
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 0rem;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin-bottom: 0.7rem;
}
p
{
    font-size: 0.9rem;
    color: #000000;
}
.social_links
{
    padding: 1rem;
    /* background: linear-gradient(180deg,rgb(17,0,154) 0%, rgb(60, 61, 155) 90%); */
    background: #000;
    border-radius: 1rem;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.social_links .combo
{
    transform: scale(1);
    transition: 0.4s ease-in;
    margin: 0.5rem 0.5rem;
    height: 3em;
    width: 9em;
    line-height: 3em;
}
.combo .circle
{
    color: #f9f9f9;
    transition: 0.4s ease-in;
    font-size: 3em;
}
.combo .icon
{
    color: #05051f;
    font-size: 2rem;
    transform: translateY(1px);
    transition: 0.2s ease-in;
}
.combo:hover
{
    transform: scale(1.1);
}
.combo:hover , .combo:hover .icon
{
    color:white;
}
.ig:hover , .ig:hover .circle
{
    color:#e91e63;
}
.fb:hover , .fb:hover .circle
{
    color:#2196f3;
}
.yt:hover , .yt:hover .circle
{
    color:#f44336;
}
.tw:hover , .tw:hover .circle
{
    color:#2196f3;
}
.gt:hover , .gt:hover .circle{
    color: #1f1f1f;
}