.loader {
	position: absolute;
	top: 71%;
	left: 50%;
	transform: translate(0, -50%);
	box-shadow: 0px 0px 5px 5px rgba(90, 29, 5, 0.8);
	text-align: center;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #fff;
}
