.services-section {
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  background-color: #05051f;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .services-section {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.cards {
  padding: 4rem;
  background: #5b5bf0;
}

.card-pop {
  /* background: hsl(293, 81%, 86%); */
  /* background: hsl(190, 81%, 89%); */
  /* background: hsl(194, 82%, 89%); */
  background: hsl(293, 81%, 90%);
}

h1 {
  text-align: center;
  color: #2b2bf7;
}

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 7%;
}

@media screen and (max-width: 500px) {
  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 15%;
  }
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding: 5rem 10rem 10rem 10rem;
  background-color: #fafafa;
}

.cards__container > h1 {
  font-size: 7vmin;
  line-height: 1.1;
}
.cards_container > p {
  font-size: 3vmin;
  line-height: 1.1;
}

html {
  block-size: 100%;
  inline-size: 100%;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: rgb(255,255,255,0.05);
}

/*card hovering effect below*/

.cards__item :hover {
  background-color: rgb(255,255,255,0.05);
  align-items: center;
}

.cards__item :hover .cards__item__text {
  color: #f9f9fa;
  font-size: 22px;
}

.cards__item :hover .cards__item__img {
  transform: scale(1.2);
}

/*card hovering effect above*/

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* box-shadow: 0 6px 20px rgb(255,255,255,0.05); */
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: rgb(255,255,255,0.05);
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.5);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

/*
.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
*/
