.particles {
	/* background-color: rgb(5, 20, 66); */
	height: 100vh;
	width: 100%;
}

.hero-container {
	background-color: #05051f;
	height: 100vh;
	width: 100%;
}

.flies-text-title {
	margin-top: -455px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
}

@media screen and (max-width: 960px) {
	.flies-text-title {
		transform: scale(0.75);
	}
}

@media screen and (max-width: 450px) {
	.flies-text-title {
		transform: scale(0.5);
	}
}

@media screen and (max-width: 350px) {
	.flies-text-title {
		transform: scale(0.4);
	}
}

@media screen and (max-width: 265px) {
	.flies-text-title {
		transform: scale(0.3);
	}
}

.flies-text-slogan {
	margin-top: -50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
	/* object-fit: contain; */
}

/*
.typing-text-slogan {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
*/

.typing-text-slogan {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	object-fit: contain;
	position: absolute;
	top: 62%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media screen and (max-width: 960px) {
	.typing-text-slogan {
		width: 100%;
	}
}

/* .hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
} */

.hero-container > h1 {
	color: #fff;
	font-size: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hero-container > p {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	object-fit: contain;
}

.cursor {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
}

.title {
	position: absolute;
	display: flex;
	top: 48%;
	left: 50%;
}

.title > h1 {
	color: #fff;
	font-family: "Times New Roman", serif;
	text-shadow: 5px 1px #19199e;
	font-weight: 500;
	font-size: 8vmax;
	transform: translate(-50%, -50%);
}

/*
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background-color: rgb(8, 8, 102);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}






.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.socialmedia a.fa {
  border: 100px solid white;
  padding: 10px;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  margin-right: 2px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
*/
