* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "PT Sans", sans-serif;
	/* background-color : #05051f; */
	/* padding: 100px 0px; */
}

.home,
.services,
.products,
.sign-up,
.aboutus {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
	padding: 100px;
}

.services {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	color: #05051f;
	font-size: 100px;
	background-color: #05051f;
}

.products {
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.sign-up {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.aboutus {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.container > h1 {
	font-size: 7vmin;
	line-height: 1.1;
	color: #fefefe;
	background-color: #05051f;
}
.about-section > p {
	font-size: 3vmin;
	line-height: 1.1;
}

html {
	block-size: 100%;
	inline-size: 100%;
	max-width: 100%;
}

body {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
	overflow-x: hidden;
	border-left: none;
	border-right: none;
  }
  
  body::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
  }
  