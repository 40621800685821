.container{
    background: #05051f;
}


.row.spec {
  margin-left: 6%;
  margin-right: 4%;
}

.tech-card {
  margin: 1vh;
  width: 13vw;
  height: 12vw;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.905);
  box-shadow: 10px 20px 50px rgba(23, 15, 68, 0.878);
  transition: box-shadow 0.5s;
  will-change: transform;
  border-radius: 3%;
}

.techImage {
    width: 80%;
    height: 80%;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.expImage {
    height: 50%;
}


  /* Responsive layout - makes a two columns-layout instead of four columnss */
  /* @media screen and (max-width: 800px) {
    .tech-container h1 {
        align-content: center;
        border-radius: 5px;
    }
    .columns {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 50%;
    }
  }
   */
  /* Responsive layout - makes the two columnss stack on top of each other instead of next to each other */
  /* @media screen and (max-width: 600px) {
    .tech-container h1 {
        align-content: center;
        border-radius: 5px;
    }
    .columns {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  } */

  .techImagesContainer {
    display: none;
  }


  @media only screen and (max-width: 1500px) {
    .row .spec{
      margin-left: 15%;
    }
  }

  @media only screen and (max-width: 600px) {
    .container-fluid{
        margin-left: 5%;
        display: none;
    }

    .tech-card {
        margin: 1%;
        margin-top: 1%;
        padding: 3%;
        width: 10%;
        height: 10%;
        margin-left: 8%;
    }

    .techImage {
        width:80%;
        height: 80%;
        margin-top: 10%;
    }

    .expImage {
        height: 50%;
    }

    .techImagesContainer {
      display :block;
      margin: 10%;
    }
}