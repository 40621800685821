* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "avenir next", avenir, -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  color: #374047;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-color: #1e282f;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex: 1 1 auto;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 60%;
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.column-small {
  display: flex;
  flex: 1 1 auto;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 13%;
}
@media screen and (max-width: 500px) {
  .column-small {
    width: 100%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding: 40px;
  /* background-color: #ffffff; */
  /* background-color: #1d223b; */
  background-color: rgba(255, 255, 255, 0.05);

  /* box-shadow: 0 20px 50px rgba(2, 9, 73, 0.8); */
  box-shadow: 10px 20px 50px rgb(8, 5, 24);
  transition: box-shadow 0.5s;
  will-change: transform;
  border-radius: 2%;
}

.card-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  /* color: #12126e; */
  color: #fff;
}

.card-body {
  margin-top: 27px;
  margin-bottom: 0px;
  line-height: 1.5;
  font-size: 20px;
  text-align: justify;
  /* color: #0b0b42; */
  color: #fff;

  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.image-container {
  margin-top: auto;
  padding-top: 27px;
  padding-bottom: 0px;
  @media screen and (min-width: 576px) {
    padding-bottom: 30px;
  }
}

.image-inner-container {
  width: 100%;
  margin: 0 auto;
  max-width: 250px;
}

.ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ratio-inner > img {
  width: 100%;
}
